<template>
    <main class="cus-game page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
        <banner-section location="games_home-top"/>
        <section v-if="Array.isArray(gamesSuppliers) && gamesSuppliers.length" class="game__brands">

            <div v-for="(supplier_item, index) in gamesSuppliers" :key="index" :style="{'background-color': supplier_item.logo_background_color}" class="brand communication__brand">
                <router-link :to="{name: 'GameSupplier', params: {id: supplier_item.id }}" tag="a" class="link brand__link" exact>
                    <img v-if="supplier_item && supplier_item.hasOwnProperty('image') && supplier_item.image" class="brand__img communication__img" :src="supplier_item.image" alt="">
                    <b v-if="supplier_item && supplier_item.hasOwnProperty('name') && supplier_item.name" class="text brand__text communication__brand-text">{{ supplier_item.name }}</b>
                </router-link>
            </div>

        </section>

        <section v-if="Array.isArray(gamesSuppliersWithBestsellers) && gamesSuppliersWithBestsellers.length" class="game__bestsellers">

            <h2 class="title game__title">{{ $t("COMMUNICATION.BEST_SELLERS") }}</h2>

            <div class="game__slider">

                <div class="game__wrapper owl-carousel js-game-bestsellers">

                    <div v-for="(supplier_item, supplier_index) in gamesSuppliersWithBestsellers" :key="supplier_index" class="category-slider ">
                        <router-link :to="{name: 'GameSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper game__p-logo-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <img class="category-slider__img game__p-logo" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>
                        <div class="category-slider__info-block">

                            <div v-for="(bestseller, bestseller_index) in supplier_item.bestsellers" :key="bestseller_index" class="category-slider__info">

                                <router-link :to="{name: 'GameProduct', params: {id: bestseller.id, supplier_id: bestseller.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name text-two-line">
                                        {{ bestseller.name }}
                                    </p>

                                    <div v-if="bestseller.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + bestseller.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            {{ 'ש"ח' }}
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

        <section v-if="Array.isArray(gamesSuppliersWithSpecials) && gamesSuppliersWithSpecials.length" class="game__special">

            <h2 class="title game__title">{{ $t("TOURISM.PROMOTIONS") }}</h2>

            <div class="game__slider">

                <div class="game__wrapper owl-carousel js-game-special">
                    <div v-for="(supplier_item, supplier_index) in gamesSuppliersWithSpecials" :key="supplier_index"  class="category-slider">
                        <router-link :to="{name: 'GameSupplier', params: {id: supplier_item.id }}" tag="a" class="link" exact>
                            <div class="category-slider__img-wrapper game__p-logo-wrapper" :style="{'background-color': supplier_item.logo_background_color}">
                                <img class="category-slider__img game__p-logo" :src="supplier_item.image" alt="">
                            </div>
                        </router-link>
                        <div class="category-slider__info-block">

                            <div v-for="(special, special_index) in supplier_item.specials" :key="special_index" class="category-slider__info">

                                <router-link :to="{name: 'GameProduct', params: {id: special.id, supplier_id: special.supplier_id }}" tag="a" class="link category-slider__link" exact>

                                    <p class="text category-slider__product-name">
                                        {{ special.name }}
                                    </p>

                                    <div v-if="special.price != null" class="category-slider__price">
                                        <b class="category-slider__price-value">
                                            {{ ' ' + special.price + ' ' }}
                                        </b>
                                        <b class="category-slider__current">
                                            {{ 'ש"ח' }}
                                        </b>
                                    </div>

                                </router-link>

                            </div>

                        </div>

                    </div>

                </div>

                <div class="category-slider__controls ">

                    <button class="button category-slider__control category-slider__control_prev">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                    <button class="button category-slider__control category-slider__control_next">
                        <div class="">
                            <img class="" src="/assets/img/chevron.svg" alt="">
                        </div>
                    </button>

                </div>

            </div>

        </section>

    </main>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import appConfig from '../../appConfig'
    import BannerSection from "../../components/BannerSection";

    export default {
        name: 'Game',
        components: {
            BannerSection,
        },
        data: () => {
            return {
                reInitBestsellersCarouselNeeded: false,
                reInitSpecialsCarouselNeeded: false
            }
        },
        mounted() {
            this.initBestsellersCarousel();
            this.initSpecialsCarousel();
        },
        computed: {
            ...mapState({
                supplier_items: state => state.supplier.supplier_items,
                suppliers_products: state => state.supplier.suppliers_products,
                product_bestseller_tag_id: state => state.supplier.product_bestseller_tag_id,
                product_special_tag_id: state => state.supplier.product_special_tag_id,
            }),
            gamesSuppliers() {
                let gamesSuppliers = this.supplier_items && this.supplier_items.hasOwnProperty('games') && Array.isArray(this.supplier_items.games) && this.supplier_items.games.length ? this.supplier_items.games : [];
                gamesSuppliers = gamesSuppliers.filter(supplier => supplier.status === 1);

                return gamesSuppliers;
            },
            gamesSuppliersWithBestsellers() {
                let gamesSuppliersWithBestsellers = []

                if (this.gamesSuppliers && this.gamesSuppliers.length && this.product_bestseller_tag_id) {
                    for (let supplier of this.gamesSuppliers) {
                        let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                            ? this.suppliers_products[supplier.id] : []
                        let supplier_bestsellers = []

                        if (supplier_products) {
                            for (let supplier_product_type in supplier_products) {
                                for (let supplier_product of supplier_products[supplier_product_type]) {
                                    if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                        && supplier_product.tags.includes(this.product_bestseller_tag_id)) {
                                        supplier_bestsellers.push(supplier_product)
                                    }
                                }
                            }
                        }

                        if (supplier_bestsellers.length) {
                            gamesSuppliersWithBestsellers.push({...supplier, bestsellers: supplier_bestsellers})
                        }
                    }
                }

                let obj = this
                obj.reInitBestsellersCarouselNeeded = true
                setTimeout(function () {
                    if (obj.reInitBestsellersCarouselNeeded) {
                        obj.reInitBestsellersCarouselNeeded = false
                        obj.reInitBestsellersCarousel()
                    }
                }, 500)

                return gamesSuppliersWithBestsellers
            },
            gamesSuppliersWithSpecials() {
                let gamesSuppliersWithSpecials = []

                if (this.gamesSuppliers && this.gamesSuppliers.length && this.product_special_tag_id) {
                    for (let supplier of this.gamesSuppliers) {
                        let supplier_products = this.suppliers_products.hasOwnProperty(supplier.id)
                            ? this.suppliers_products[supplier.id] : []
                        let supplier_specials = []

                        if (supplier_products) {
                            for (let supplier_product_type in supplier_products) {
                                for (let supplier_product of supplier_products[supplier_product_type]) {
                                    if (supplier_product.hasOwnProperty('tags') && supplier_product.tags.length
                                        && supplier_product.tags.includes(this.product_special_tag_id)) {
                                        supplier_specials.push(supplier_product)
                                    }
                                }
                            }
                        }

                        if (supplier_specials.length) {
                            gamesSuppliersWithSpecials.push({...supplier, specials: supplier_specials})
                        }
                    }
                }

                let obj = this
                obj.reInitSpecialsCarouselNeeded = true
                setTimeout(function () {
                    if (obj.reInitSpecialsCarouselNeeded) {
                        obj.reInitSpecialsCarouselNeeded = false
                        obj.reInitSpecialsCarousel()
                    }
                }, 500)

                return gamesSuppliersWithSpecials
            }
        },
        created () {
            this.getAllSupplierItems({params: {filter: true}})
            this.getProductTags()
        },
        methods: {
            ...mapActions('supplier', {
                getAllSupplierItems: 'getAll',
                getProductTags: 'getProductTags',
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            reInitBestsellersCarousel() {
                $('.js-game-bestsellers').each(function () {
                    $(this).trigger('destroy.owl.carousel');
                });
                this.initBestsellersCarousel();
            },
            reInitSpecialsCarousel() {
                $('.js-game-special').each(function () {
                    $(this).trigger('destroy.owl.carousel');
                });
                this.initSpecialsCarousel();
            },
            initBestsellersCarousel() {
                let obj = this;
                $('.js-game-bestsellers').each(function () {
                    $(this).owlCarousel({
                        rtl: true,
                        dots: false,
                        responsive: {
                            0: {
                                items: 1
                            },
                            768: {
                                items: 2,
                                margin: 20
                            },
                            1366: {
                                items: 4,
                                margin: 20
                            }
                        }
                    });
                    $(this).closest('.game__bestsellers').find('.category-slider__control_next').off('click', obj.nextSlide__bestseller).on('click', obj.nextSlide__bestseller);
                    $(this).closest('.game__bestsellers').find('.category-slider__control_prev').off('click', obj.prevSlide__bestseller).on('click', obj.prevSlide__bestseller);
                });
            },
            initSpecialsCarousel() {
                let obj = this;
                $('.js-game-special').each(function () {
                    $(this).owlCarousel({
                        rtl: true,
                        dots: false,
                        responsive: {
                            0: {
                                items: 1
                            },
                            768: {
                                items: 2,
                                margin: 20
                            },
                            1366: {
                                items: 4,
                                margin: 20
                            }
                        }
                    });
                    $(this).closest('.game__special').find('.category-slider__control_next').off('click', obj.nextSlide__special).on('click', obj.nextSlide__special);
                    $(this).closest('.game__special').find('.category-slider__control_prev').off('click', obj.prevSlide__special).on('click', obj.prevSlide__special);
                });
            },
            nextSlide__bestseller: function (e) {
                e.preventDefault();
                $(e.target).closest('.game__bestsellers').find('.js-game-bestsellers').trigger('next.owl.carousel');
            },
            prevSlide__bestseller: function (e) {
                e.preventDefault();
                $(e.target).closest('.game__bestsellers').find('.js-game-bestsellers').trigger('prev.owl.carousel');
            },
            nextSlide__special: function (e) {
                e.preventDefault();
                $(e.target).closest('.game__special').find('.js-game-special').trigger('next.owl.carousel');
            },
            prevSlide__special: function (e) {
                e.preventDefault();
                $(e.target).closest('.game__special').find('.js-game-special').trigger('prev.owl.carousel');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .game__brands {
        justify-content: flex-start;
    }
    .cus-game {
        flex-basis: 100%;
        padding: 0 90px 50px;
        box-sizing: border-box;
        overflow: hidden;
    }
    .category-slider__product-name {
        max-width: 72%;
    }
    .category-slider__link::before {
        top: 50%;
        transform: translateY(-50%);
    }
    .category-slider__link::after {
        top: 50%;
        transform: translateY(-50%);
        width: 21px;
        height: 21px;
    }
    .category-slider__img-wrapper {
        height: 80px;
        padding: 10px;
    }
    .brand__img {
        position: absolute;
        top: 0;
        height: calc(100% - 24px);
    }
    .communication__brand-text {
        width: 100%;
        height: 24px;
        bottom: 0;
        background: rgba(0,0,0,0.2);
    }
    .communication__brand {
        flex-basis: calc((100% - 100px) / 6);
        margin: 10px;
        box-sizing: border-box;
    }
    @media screen and (min-width: 1366px) {
        .communication__brand:nth-child(n+7) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(6n+1) {
            margin-right: 0;
        }
        .communication__brand:nth-child(6n+6) {
            margin-left: 0;
        }
    }
     @media screen and (max-width: 1365px) and (min-width: 768px) {
        .communication__brand {
            flex-basis: calc((100% - 60px) / 4);
        }
        .communication__brand:nth-child(n + 5) {
            margin-top: 10px;
        }
        .communication__brand:nth-child(4n + 1) {
            margin-right: 0;
        }

        .communication__brand:nth-child(4n + 4) {
            margin-left: 0;
        }
    }
    @media screen and (max-width: 767px) {
        .communication__brand {
            flex-basis: calc((100% - 40px) / 4);
            margin: 5px;
            height: 90px;
        }
       .cus-game {
           padding: 0;
       } 
       .game__brand {
           flex-basis: calc(25% - 5px);
           height: 60px;
       }
       .game__bestsellers,
       .game__special {
           margin-top: 20px;
       }
    }
</style>